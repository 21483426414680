import React from 'react'
import Constant from '../../Constant'

function Topsec() {
  return (
    <div className='bg-primary-color5 '>
 <div className=' pt-[140px] pb-20  sm:pt-[177px] '>
        <div className=' flex mx-auto px-6 sm:px-0 sm:w-[75%] md:w-[50%] justify-center'>
            <div>
            
             <h1 className=' text-[24px] mb-6 font-[24px] text-center text-text-color1 sm:text-[48px]'>Halal wealth <span className={Constant.linearColor}>Privacy</span> policy</h1>
             <div className=' flex justify-center'>
            <p className=' inline-block text-center bg-white p-3 text-[12px] font-[500] rounded-[32px] text-primary-color mb-4'> Last Updated: September 1, 2023 </p>
             </div>
            </div>
        </div>
      
    </div>
    </div>
  )
}

export default Topsec