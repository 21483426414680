import React from 'react'

function Inputtt({placeholder, label, type, onChange, value}) {
  return (
    <div className=' my-2'>
    <label className=' block mb-3 text-back-color2'>{label}</label>
        <input 
            className=' w-full bg-[#Fff] outline-0 border border-gray-100 p-2.5 rounded-lg'
            placeholder={placeholder}
            type={type}
            onChange={(e) => onChange(e.target.value)}
            value={value}
        />
    </div>
  )
}

export default Inputtt