import React from 'react'

function FaqCom({header, color, con, textColor}) {
  return (
    <div className={` rounded-t-[32px] min-h-[350px] ${color} px-6 sm:px-24 py-10`}>
        <h1 className= {`text-[14px] mb-6 sm:text-[24px] ${textColor}`}>{header}</h1>
        <p className={` ${textColor} text-[14px] mb-6 sm:text-[24px] sm:w-[85%]`}>{con}</p>
    </div>
  )
}

export default FaqCom