import React from 'react'
import Arrow from '../../Assets/images/sarrow.png'
function MeetCom({img, name, role}) {
  return (
    <div className=' mb-5'>
        <img src={img} className=' mb-4' alt={name}/>
        <p className=' text-text-color1 font-[600] text-[18px] mb-4'>{name}</p>
        <p className=' text-[#5C556C] font-[500] text-[18px] mb-4'>{role}</p>

        <p className=' text-[#1A73E8] font-[500] text-[18px] mb-4'>LinkedIn</p>
        <img src={Arrow} alt='arrow'/>
    </div>
  )
}

export default MeetCom