import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function AssetsF() {
   
  return (
    <div className=' px-6 sm:px-24 py-20 bg-primary-color5'>
        <div className=' sm:flex  gap-5'>
            <div>
                <h1 className=' text-[24px] text-primary-color font-[400] mb-4 sm:text-[44px]'>Asset
Financing</h1>
            </div>
            <div>
            <Accordion 
            elevation={0}
            style={{ backgroundColor: 'transparent' }}
            sx={{
            '&:before': {
                display: 'none',
                color: 'transparent'
            }
        }}
            >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className=' text-black'
        >
          <Typography><h1 className=' text-[16px] sm:text-[24px] font-[400] text-text-color1'>1. How can I apply for asset financing on Halal Wealth?</h1> </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p className=' text-[16px] sm:text-[24px]  text-text-color1 font-normal'>
          To apply for asset financing, navigate to the "Asset Financing" section, complete the application form, and submit the required documents. Our team will review your application and provide further instructions.
          </p>
          </Typography>
        </AccordionDetails>
            </Accordion>
            <Accordion
            elevation={0}
            style={{ backgroundColor: 'transparent' }}
            >
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                >
                <Typography><h1 className=' text-[16px] sm:text-[24px] font-[400] text-text-color1'>2. What types of assets can I finance through Halal Wealth?</h1> </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                <p className=' text-[16px] sm:text-[24px]  text-text-color1 font-normal'>
                Halal Wealth offers financing options for various assets, including vehicles, real estate, and equipment. Contact our support team for specific details on eligible assets.
                </p>
                </Typography>
                </AccordionDetails>
            </Accordion>
     
            </div>
        </div>
    </div>
  )
}

export default AssetsF