import React from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
function SaveCom({img, header, con}) {
  return (
    <div className=' mb-8 px-2'>
    <div className=' mb-5 flex gap-3'>
    <div>
    <img src={img} alt='savingimage'/>
    </div>
   <div className=' flex flex-col justify-center'>
   <h1 className=' text-[20px] sm:text-[24px] font-normal text-text-color1'>{header}</h1>
   </div>
   
    </div>
    <p className=' font-normal text-[14px] sm:text-[18px] text-text-color1 mb-6'>{con}</p>
    <p className=' text-[14px] font-medium mb-4 text-primary-color'>Create a savings plan now <KeyboardArrowRightIcon className=' text-primary-color'/></p>
    </div>
  )
}

export default SaveCom