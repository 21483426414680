import React from 'react'
import Bg from '../../Assets/images/hhand1.png'
import Bgs from '../../Assets/images/hhand2.png'
import Star from '../../Assets/images/star.svg'
import Google from '../../Assets/images/gplay.svg'
import Apple from '../../Assets/images/appP.svg'
import Googles from '../../Assets/images/ggp.svg'
import Apples from '../../Assets/images/aap.svg'
function TopSec() {
  return (
    <div className=' body-bg '>
    <div className=' pt-[140px] sm:pt-[177px] grid sm:grid-cols-3 gap-4'>
    <div className=' sm:col-span-2 mb-10  px-6 sm:px-24'>
    <div>
    <div>
            <p className=' inline-block bg-white p-3 text-[12px] font-[500] rounded-[32px] text-primary-color mb-4'> <img src={Star} alt='Start' className=' inline'/> Empowering Ethics, Enriching Futures. </p>
      </div>
      <h1 className=' text-[24px] mb-6 font-[24px] text-text-color1 sm:text-[48px]'>Explore Opportunities for <span className='bg-clip-text text-transparent bg-gradient-to-r from-[#FF75E8] to-[#8046F2]'>Sustainable Savings, Ethical Investments,</span> & Asset Financing.</h1>
    <p className=' text-[14px] mb-10 font-[500] text-text-color1  sm:text-[20px]'>Experience responsible savings, explore diverse investment avenues, and participate in impactful asset financing – all on one platform, designed for your financial growth and ethical beliefs.</p>
    <div >
    <div className=' sm:flex gap-5'>
       <img src={Google} className=' hidden sm:block ' alt='Google play store'/>
        <img src={Apple} className='hidden sm:block' alt='Apple play store'/>
        <img src={Googles} className=' block mb-3 sm:mb-0 mx-auto sm:hidden ' alt='Google play store'/>
        <img src={Apples} className=' block mx-auto sm:hidden' alt='Apple play store'/>
      </div>
    </div>
    </div>
    </div>
    <div className='px-10 sm:pt-20'>
    <img src={Bg}  alt='home' className=' hidden sm:block'/>
    <img src={Bgs}  alt='home' className='sm:hidden block'/>
    </div>
    </div>
   
    </div>
  )
}

export default TopSec