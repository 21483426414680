import React from 'react'
import Topsec from '../../Component/Privacy/Topsec'
import Content from '../../Component/Privacy/Content'

function Privacy() {
  return (
    <div>
      <Topsec/>
      <Content/>
    </div>
  )
}

export default Privacy