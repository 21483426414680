import React from 'react'
import TopSec from '../../Component/Investment/Topsec'
import TopInvestment from '../../Component/Investment/TopInvestment'

function Investment() {
  return (
    <div>
      <TopSec/>
      <TopInvestment/>
      
    </div>
  )
}

export default Investment