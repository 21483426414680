import React from 'react'
import TopSec from '../../Component/About/Topsec'
import Who from '../../Component/About/Who'
import Core from '../../Component/About/Core'
import Meet from '../../Component/About/Meet'

function Aboutus() {
  return (
    <div>
      <TopSec/>
      <Who/>
      <Core/>
      <Meet/>
    </div>
  )
}

export default Aboutus