import React from 'react'
import Button from '../SelectValue/Button'
import E1 from '../../Assets/images/e1.png'
import E1s from '../../Assets/images/e1s.png'
function Embrace() {
  return (
    <div className='px-6 mt-16 sm:px-24'>
        <h1 className=' text-[24px]  sm:text-[44px] font-[400] '>Embrace <span className='bg-clip-text text-transparent bg-gradient-to-r from-[#FF75E8] to-[#8046F2]'>Ethical</span> Investments</h1>
        <div className=' mt-8 mb-3 grid sm:grid-cols-2 gap-4'>
            <p className='text-[14px] sm:text-[18px] font-[400] text-text-color1'>With us, you have the power to invest in companies and projects that align with your values. We meticulously curate options that prioritize environmental sustainability, social responsibility, and ethical business practices. Join us in forging a future where your financial growth goes hand in hand with making a meaningful impact on the issues that matter to you.</p>
        <p className=' text-[14px] sm:text-[18px] font-[400] text-text-color1'>At Halal Wealth, we believe that your investments can do more than just generate returns – they can create positive change. Our commitment to ethical investments means that every choice you make here contributes to a better world.</p>
        </div>
        <Button
            name='Learn more'
        />
        <div className=' my-6'>
            <img src={E1} className=' hidden sm:block' alt='Embrace'/>
            <img src={E1s} className='sm:hidden block' alt='Embrace'/>
        </div>
    </div>
  )
}

export default Embrace