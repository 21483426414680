import React from 'react'
import Constant from '../../Constant'
import WhyComponent from './WhyComponent'

function Why() {
  return (
    <div className=' px-6 sm:px-24 mb-20 '>
        <h1 className=' text-text-color1 font-normal text-[24px] sm:text-[44px] mb-8'>Why <span className={Constant.linearColor}>save</span> with us</h1>
        <div className=' grid sm:grid-cols-2 md:grid-cols-3 gap-6'>
        <WhyComponent
            header={"Positive impact"}
            content={"Ethical savings allow you to put your money to work for causes you believe in. By choosing responsible and sustainable savings options, you contribute to projects that have a positive impact on the environment, social well-being, and community development."}
        />
        <WhyComponent
            header={"Alignment with Values"}
            content={"With ethical savings, your financial decisions reflect your values. You can rest assured that your money is not supporting industries or practices that contradict your ethical beliefs. This alignment adds a sense of purpose and satisfaction to your savings journey."}
        />
         <WhyComponent
            header={"Financial Security with a Conscience"}
            content={"Ethical savings provide the opportunity to grow your wealth while maintaining a clear conscience. You can enjoy competitive returns on your savings, knowing that your money is being channeled into responsible investments that benefit society and the planet."}
        />
        </div>
    </div>
  )
}

export default Why