import React from 'react'

function CoreComponent({image, color, header, con}) {
  return (
    <div className={`${color} border rounded-[24px] px-3 pt-6`}>
        <h1 className=' text-[18px] sm:text-[24px] font-[400] text-text-color1 mb-5'>{header}</h1>
        <p className='sm:text-[18px] text-[14px] mb-6 font-[500] text-text-color1'>{con}</p>
        <div className=' flex justify-end'>
            <img src={image} alt='Core pic'/>
        </div>
    </div>
  )
}

export default CoreComponent