import React from 'react'
import Star from '../Assets/images/star.svg'
import Inputtt from './SelectValue/Inputtt'
import Button from './SelectValue/Button'
import Logo from '../Assets/images/halogoo.svg'
import Ig from '../Assets/images/ig.svg'
import Twitter from '../Assets/images/tt.svg'
import Facebook from '../Assets/images/ff.svg'
import LinkedIn from '../Assets/images/lin.svg'
import Google from '../Assets/images/gplay.svg'
import Apple from '../Assets/images/appP.svg'
import { Link } from 'react-router-dom'
function Footer() {
  return (
    <div className=' bg-text-color1  p-5 sm:pt-16 sm:p-10'>
        <div className=' flex mb-5 justify-center'>
         <div>
            <p className=' inline-block bg-white p-3 text-[12px] font-[500] rounded-[32px] text-primary-color mb-4'> <img src={Star} alt='Start' className=' inline'/> Join us on this mission </p>
         </div>
        </div>
        <div className=' sm:w-[60%] mb-5 mx-auto sm:flex sm:justify-center'>
                <h1 className=' font-normal text-[24px] text-center sm:text-[44px] text-white'>Subscribe to our newsletter. Be the first to <span className='bg-clip-text text-transparent bg-gradient-to-r from-[#FF75E8] to-[#8046F2]'>get updates</span>  from us</h1>
            </div>
            <div className='sm:w-[60%] mx-auto mb-5  sm:flex sm:justify-center '>
                <div className=' grid  sm:grid-cols-4 gap-5'>
                <div className='  sm:col-span-3'>
                <Inputtt
                    placeholder='Enter email address'
                    />
                </div>
                   
                    <div className=' flex flex-col justify-center mt-1'>
                    <Button
                        name='Subscribe'
                    />
                    </div>
                    
                </div>
            </div>
            <div className=' mt-16 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-5 '>
                <div className=' col-span-2 sm:col-span-1'>
                    <img src={Logo} className=' mb-2' alt='logo'/>
                    <p className=' text-text-gray text-[16px] mb-3 font-medium'>91, Ogudu road, Ojota. Lagos, Nigeria</p>
                    <div className=' flex gap-5'>
                        <div>
                            <img src={Facebook} alt='Facebook'/>
                        </div>
                        <div>
                            <img src={Twitter} alt='Twitter'/>
                        </div>
                        <div>
                            <img src={Ig} alt='Ig'/>
                        </div>
                        <div>
                            <img src={LinkedIn} alt='LinkedIn'/>
                        </div>
                    </div>
                </div>
                <div>
                    <p className=' text-primary-color2 font-bold text-[18px] mb-3'>Features</p>
                    <Link to='/investment'><p className=' text-text-gray mb-3 font-[500] text-[16px]'>Investment</p></Link>
                    <Link to='/assetsfinancing'><p className=' text-text-gray mb-3 font-[500] text-[16px]'>Asset financing</p></Link>
                    <Link to='/savings'><p className=' text-text-gray mb-3 font-[500] text-[16px]'>Savings</p></Link>
                </div>
                <div>
                    <p className=' text-primary-color2 font-bold text-[18px] mb-3'>Company</p>
                    <Link to='/aboutus'><p className=' text-text-gray mb-3 font-[500] text-[16px]'>About us</p></Link>
                    <Link to='/blog'><p className=' text-text-gray mb-3 font-[500] text-[16px]'>Blog</p></Link>
                </div>
                <div>
                    <p className=' text-primary-color2 font-bold text-[18px] mb-3'>Help</p>
                    <Link to='/faq'><p className=' text-text-gray mb-3 font-[500] text-[16px]'>FAQ</p></Link>
                    <Link to='/contactus'><p className=' text-text-gray mb-3 font-[500] text-[16px]'>Contact us</p></Link>
                </div>
                <div>
                    <p className=' text-primary-color2 font-bold text-[18px] mb-3'>Legal</p>
                    <Link to='/privacy'><p className=' text-text-gray mb-3 font-[500] text-[16px]'>Privacy policy</p></Link>
                    <Link to='/terms'><p className=' text-text-gray mb-3 font-[500] text-[16px]'>Terms & Conditions</p></Link>
                </div>
                <div>
                    <img src={Google} className=' mb-3' alt='Google play store'/>
                    <img src={Apple} alt='Apple play store'/>
                </div>
            </div>
            <div className='mt-28 mb-16'>
                <p className=' text-primary-color4 font-[500] text-[14px]'>©2023 Halal wealth is a financial service licensed and regulated by the Central Bank of Nigeria (CBN). We empower individuals with credit, simple payments solutions, high-yield investment opportunities, and easy-to-use tools for personal financial management. We are a global company of over 90 employees with a presence in Nigeria.</p>
            </div>
    </div>
  )
}

export default Footer