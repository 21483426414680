import React from 'react'
import TopSec from '../../Component/Home/TopSec'
import Embrace from '../../Component/Home/Embrace'
import Sustain from '../../Component/Home/Sustain'
import Why from '../../Component/Home/Why'
import Word from '../../Component/Home/Word'
import Faq from '../../Component/Home/Faq'

function Home() {
  return (
    <div className='  '>
      <TopSec/>
      <Embrace/>
      <Sustain/>
      <Why/>
      <Word/>
      <Faq/>
    </div>
  )
}

export default Home