import React from 'react'

function Tcom({img, con}) {
  return (
    <div className=' inline-block pr-5 bg-white rounded-[6px] p-3 border-[#FFF4FF]'>
        <img src={img} className=' mb-4' alt='img'/>
        <p className='font-[600] text-[14px]'>{con}</p>
    </div>
  )
}

export default Tcom