// AccordionCard.js
import React, { useState } from 'react';
import { motion } from 'framer-motion';

const AccordionCard = ({header, color, con, textColor, zIndex}) => {
  const [isHovered, setIsHovered] = useState(false);

  const toggleHover = () => {
    setIsHovered(!isHovered);
  };
 
  return (
    <div
      className={`relative accordion-card ${
        isHovered ? 'h-[270px]' : 'h-32'
      } border rounded-t-[32px]   ${color} px-6 sm:px-24  w-full overflow-hidden transition-height ease-in-out duration-500`}
   style={{ zIndex: zIndex }}
    >
      <motion.div
        className={`card-header py-4 cursor-pointer  hover:scale-100 transition-transform`}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
      >
       <h1 className= {`text-[14px] pt-6 mb-6 sm:text-[24px] ${textColor}`}>{header}</h1>
      </motion.div>
      {isHovered && (
        <div
          className={`card-content `}
         
        >
         <p className={` ${textColor} text-[14px] mb-6 sm:text-[24px] `}>{con}</p>
        </div>
      )}
    </div>
  );
};

export default AccordionCard;
