import React from 'react'
import Constant from '../../Constant'
import WhyComponent from './WhyComponent'

function Why() {
  return (
    <div className=' p-6 bg-text-color1 sm:p-24'>
        <h1 className=' text-[24px] md:w-[80%] sm:text-[44px] mb-6 text-text-gray font-normal'>Why Choose Us: <span className={Constant.linearColor}> Redefining Ethical Investments</span> for a Responsible Future</h1>
        <div className=' grid sm:grid-cols-2 gap-4 mb-4 md:grid-cols-3'>
            <WhyComponent
                header='Values-Aligned Growth:'
                content='Halal wealth offers a curated selection of investments that align with Islamic principles, ensuring that your financial success is in harmony with your beliefs.'
            />
             <WhyComponent
                header='Positive Impact'
                content='Your investments support projects that have a social and environmental impact, allowing you to be part of a movement that leaves a lasting mark.'
            />
             <WhyComponent
                header='Tailored Financial Solutions'
                content="From responsible savings plans to halal asset financing options, we're committed to offering solutions that empower you to make sound financial decisions in alignment with your values."
            />
        </div>
    </div>
  )
}

export default Why