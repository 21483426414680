import React from 'react'
import TopSec from '../../Component/Assets/Topsec'
import Benefit from '../../Component/Assets/Benefit'
import Unlock from '../../Component/Assets/Unlock'

function AssetManangement() {
  return (
    <div>
      <TopSec/>
      <Benefit/>
      <Unlock/>
    </div>
  )
}

export default AssetManangement