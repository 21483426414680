import React from 'react'
import Topsec from '../../Component/Contact/Topsec'
import Contactuss from '../../Component/Contact/Contactus'

function Contactus() {
  return (
    <div>
      <Topsec/>
      <Contactuss/>
    </div>
  )
}

export default Contactus