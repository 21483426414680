import React from 'react'
import TopSec from '../../Component/Savings/Topsec'
import Save from '../../Component/Savings/Save'
import Why from '../../Component/Savings/Why'

function Savings() {
  return (
    <div>
      <TopSec/>
      <Save/>
      <Why/>
    </div>
  )
}

export default Savings