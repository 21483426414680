import React from 'react'
import Mail from '../../Assets/images/maill.svg'
import Call from '../../Assets/images/ca.svg'
import Inputtt from '../SelectValue/Inputtt'
import Textarea from '../SelectValue/TextArea'
import Button from '../SelectValue/Button'
function ContactForm() {
  return (
    <div className=' bg-white rounded-[24px] px-4 sm:px-16 my-8 border  p-4 sm:p-10 grid sm:grid-cols-2 gap-4'>
        <div>
            <h1 className=' text-[20px] sm:text-[24px] text-primary-color mb-4'>Chat with our friendly team</h1>
            <p className=' text-[14px] sm:text-[16px] text-text-color1 mb-4'>We’d love to hear from you. Please fill out this form or shoot us an email.</p>
            <div className=' grid grid-cols-2 gap-4 sm:block'>   
        <div className=' my-4 '>
            <img src={Mail} alt='Mail'/>
            <p className=' text-[14px] sm:text-[16px] text-text-color1 font-medium my-4'>Email</p>
            <p className=' text-[14px] sm:text-[16px] text-text-color1 font-medium mb-3'>Our friendly team is here to help</p>
            <p  className=' text-[14px] sm:text-[16px] text-text-color1 font-medium mb-3'>Hello@Halalwealth.com</p>
        </div>
        <div className=' my-4 '>
            <img src={Call} alt='Call'/>
            <p className=' text-[14px] sm:text-[16px] text-text-color1 font-medium my-4'>Phone</p>
            <p className=' text-[14px] sm:text-[16px] text-text-color1 font-medium mb-3'>Mon-Fri from 8am to 5pm</p>
            <p  className=' text-[14px] sm:text-[16px] text-text-color1 font-medium mb-3'>+234 4849 999 222</p>
        </div>
        </div>
        </div>
        <div className=' bg-text-gray rounded-xl p-6'>
        <form >
        <div className=' grid grid-cols-2 gap-5'>
            <div>
            <Inputtt
                label='Firstname'
                type='text'
              />  
            </div>
            <div>
            <Inputtt
                label='Lastname'
                type='text'
              />  
            </div>
            </div>
            <div>
            <Inputtt
                label='Email'
                type='email'
              />  
            </div>
            <div>
                <Textarea
                    label='Message'
                />
            </div>
            <div className=' grid grid-cols-1 sm:flex sm:justify-end mt-3'>
                <Button
                    name='Send message'
                />
            </div>
        </form>
            
        </div>
    </div>
  )
}

export default ContactForm