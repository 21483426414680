import React from 'react'
import Constant from '../../Constant'
import First from '../../Assets/images/ts1.svg'
import Sec from '../../Assets/images/ts2.svg'
import Third from '../../Assets/images/ts3.svg'
import Fourth from '../../Assets/images/ts4.svg'
import Five from '../../Assets/images/ts5.svg'
import BgB from '../../Assets/images/spB.png'
import BgS from '../../Assets/images/spS.png'
import SaveCom from './SaveCom'
function Save() {
  return (
    <div className=' px-6 sm:px-24 py-8 sm:py-16 bg-text-gray mb-20'>
        <div className=' grid mb-8 sm:grid-cols-2 gap-5 '>
            <div className=''>
                <h1 className=' font-[400] text-[24px] sm:text-[44px] mb-4 text-text-color1'><span className={Constant.linearColor}>Savings for</span>  the things that matter</h1>
                <p className=' font-normal text-[14px] sm:text-[18px] text-text-color1 mb-6'>Whether you're saving for a milestone, an important purchase, or simply for peace of mind, our Regular Ethical Savings plan allows you to save with purpose.</p>
            </div>
        </div>
        <div className=' hidden sm:block  my-8'>
        <div className=' grid  sm:grid-cols-3 my-3'>
            <div className=' flex flex-col justify-between'>
                <SaveCom
                    img={First}
                    header={"Regular savings"}
                    con={"Whether you're saving for a milestone, an important purchase, or simply for peace of mind, our Regular Ethical Savings plan allows you to save with purpose."}
                />
                 <SaveCom
                    img={Third}
                    header={"Hajj savings"}
                    con={"Whether you're saving for a milestone, an important purchase, or simply for peace of mind, our Regular Ethical Savings plan allows you to save with purpose."}
                />
            </div>
            <div>
                <img src={BgB} alt='phone background'/>
            </div>
            <div className=' flex flex-col justify-between'>
                <SaveCom
                    img={Sec}
                    header={"Aqeeqah savings"}
                    con={"Whether you're saving for a milestone, an important purchase, or simply for peace of mind, our Regular Ethical Savings plan allows you to save with purpose."}
                />
                 <SaveCom
                    img={Fourth}
                    header={"Nikkah savings"}
                    con={"Whether you're saving for a milestone, an important purchase, or simply for peace of mind, our Regular Ethical Savings plan allows you to save with purpose."}
                />
            </div>
        </div>
        <div className=' flex mx-auto justify-center '>
        <div className=' w-[35%]'>
                <SaveCom
                    img={Five}
                    header={"Eid savings"}
                    con={"Whether you're saving for a milestone, an important purchase, or simply for peace of mind, our Regular Ethical Savings plan allows you to save with purpose."}
                />
        </div>
       
        </div>
        </div>
        <div className=' block sm:hidden'>
            <img src={BgS} alt='phone background' className=' mb-10'/>
            <SaveCom
                    img={First}
                    header={"Regular savings"}
                    con={"Whether you're saving for a milestone, an important purchase, or simply for peace of mind, our Regular Ethical Savings plan allows you to save with purpose."}
                />
                 <SaveCom
                    img={Third}
                    header={"Hajj savings"}
                    con={"Whether you're saving for a milestone, an important purchase, or simply for peace of mind, our Regular Ethical Savings plan allows you to save with purpose."}
                />
                 <SaveCom
                    img={Sec}
                    header={"Aqeeqah savings"}
                    con={"Whether you're saving for a milestone, an important purchase, or simply for peace of mind, our Regular Ethical Savings plan allows you to save with purpose."}
                />
                 <SaveCom
                    img={Fourth}
                    header={"Nikkah savings"}
                    con={"Whether you're saving for a milestone, an important purchase, or simply for peace of mind, our Regular Ethical Savings plan allows you to save with purpose."}
                />
                <SaveCom
                    img={Five}
                    header={"Eid savings"}
                    con={"Whether you're saving for a milestone, an important purchase, or simply for peace of mind, our Regular Ethical Savings plan allows you to save with purpose."}
                />
        </div>
        
    </div>
  )
}

export default Save