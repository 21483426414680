import React from 'react'
import Bg from '../../Assets/images/ass1.png'
import Bgs from '../../Assets/images/ass2.png'
import Star from '../../Assets/images/star.svg'
import Constant from '../../Constant'
import Button from '../SelectValue/Button'
function TopSec() {
  return (
    <div className=' body-bg '>
    <div className=' pt-[140px] sm:pt-[177px] grid sm:grid-cols-3 gap-4'>
    <div className=' sm:col-span-2 mb-10  px-6 sm:px-24'>
    <div>
    <div>
            <p className=' inline-block bg-white p-3 text-[12px] font-[500] rounded-[32px] text-primary-color mb-4'> <img src={Star} alt='Start' className=' inline'/> Empowering Ethics, Enriching Futures. </p>
      </div>
      <h1 className=' text-[24px] mb-6 font-[24px] text-text-color1 sm:text-[48px]'>Fueling Your Aspirations with  <span className={Constant.linearColor}>Ethical Asset Financing.</span></h1>
    <p className=' text-[14px] mb-10 font-[500] text-text-color1  sm:text-[20px]'>With our ethical asset financing options, you can access the items you need while adhering to your principles. Say goodbye to interest fees, as we offer installment plans that align with your financial well-being.</p>
    <div className=' grid grid-cols-1 sm:flex'>
    <Button
        name={"Get started"}
    />
    </div>
    </div>
    </div>
    <div className='px-10 sm:pt-20'>
    <img src={Bg}  alt='home' className=' hidden sm:block'/>
    <img src={Bgs}  alt='home' className='sm:hidden block'/>
    </div>
    </div>
   
    </div>
  )
}

export default TopSec