
import { NavLink, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Logo from '../Assets/images/halogo.svg'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Button from "./SelectValue/Button";
import Button2 from "./SelectValue/Button2";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  
  boxShadow: 24,
 
};
export default function NavBar() {
    const [navbar, setNavbar] = useState(false);
    const [clientWindowHeight, setClientWindowHeight] = useState("");
    const [changeBg, setChangeBg] = useState(false)
    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    });
  
    const handleScroll = () => {
      setClientWindowHeight(window.scrollY);
    };

    useEffect(() =>{
      let backgroundTransparacyVar = clientWindowHeight / 610;
      if (backgroundTransparacyVar < 1) {
          setChangeBg(true)
      }
      else{
        setChangeBg(false)
      }
    }, [clientWindowHeight])
    const [open, setOpen] = useState(false)
    const handleMouseEnter = () => {
      setOpen(true);
    };
  
    const handleMouseLeave = () => {
      setOpen(false);
    };
    const [open1, setOpen1] = useState(false)
    const handleMouseEnter1 = () => {
      setOpen1(true);
    };
  
    const handleMouseLeave1 = () => {
      setOpen1(false);
    };
    const [open2, setOpen2] = useState(false)
    const handleMouseEnter2 = () => {
      setOpen2(true);
    };
  
    const handleMouseLeave2 = () => {
      setOpen2(false);
    };
  
    return (
        <nav className= {changeBg ? `w-full bg-primary-color5 fixed  z-40    `: `w-full z-40 fixed  bg-primary-color5   ` }>
            <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-4">
                <div>
                    <div className="flex items-center justify-between py-3 md:py-5 md:block">
                        <Link to='/' >
                            <img src={Logo} width='110' height='110' className=" "  alt=" logo" /> 
                        </Link>
                        <div className="md:hidden">
                            <button
                                className="p-2  text-white rounded-md outline-none bg-[#F5F7FA]  focus:border"
                                onClick={() => setNavbar(!navbar)}
                            >
                                {!navbar && (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 text-black h-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                    </svg>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
               
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div className=" hidden md:flex md:order-2">
                <div className=" flex flex-col pr-3 justify-center">
                <div>
                    <div
                        className={`flex justify-self-start pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
                            navbar ? "hidden" : "hidden"
                        }`}
                    >
                    <div className="  flex justify-evenly">
                        <ul className="items-start justify-start space-y-8 md:flex md:space-x-6 md:space-y-0">
                        <div
                          className="relative inline-block"
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                        <button className=" text-text-color1  font-[500px] text-[14px] px-4 py-2 rounded">
                            Features  {open ? <KeyboardArrowDownIcon/> :<KeyboardArrowUpIcon/>}
                          </button>
                          
                          {open && 
                            <div className="absolute xl:w-[140px] left-0 top-full bg-white border border-gray-300 p-4 shadow-md">
                            <NavLink className={({isActive}) =>{
                            return( ' text-text-color1 block my-3 font-[500] text-[14px]' + (
                              !isActive ? ' hover:text-primary-color ' : ' text-primary-color'
                            ))
                            }}   to='/investment'>
                                Investment
                                </NavLink>
                                <NavLink className={({isActive}) =>{
                            return( ' text-text-color1 block my-3 font-[500] text-[14px]' + (
                              !isActive ? ' hover:text-primary-color ' : ' text-primary-color'
                            ))
                            }}   to='/assetsfinancing'>
                                Asset financing
                                </NavLink>
                                <NavLink className={({isActive}) =>{
                            return( ' text-text-color1 block my-3 font-[500] text-[14px]' + (
                              !isActive ? ' hover:text-primary-color ' : ' text-primary-color'
                            ))
                            }}   to='/savings'>
                                Savings
                                </NavLink>
                            </div>
                          }
                        </div>
                        <div
                          className="relative inline-block"
                          onMouseEnter={handleMouseEnter1}
                          onMouseLeave={handleMouseLeave1}
                        >
                        <button className=" text-text-color1  font-[500px] text-[14px] px-4 py-2 rounded">
                        Company  {open1 ? <KeyboardArrowDownIcon/> :<KeyboardArrowUpIcon/>}
                          </button>
                          
                          {open1 && 
                            <div className="absolute xl:w-[120px] left-0 top-full bg-white border border-gray-300 p-4 shadow-md">
                            <NavLink className={({isActive}) =>{
                            return( ' text-text-color1 block my-3 font-[500] text-[14px]' + (
                              !isActive ? ' hover:text-primary-color ' : ' text-primary-color'
                            ))
                            }}   to='/aboutus'>
                                About us
                                </NavLink>
                                <NavLink className={({isActive}) =>{
                            return( ' text-text-color1 block my-3 font-[500] text-[14px]' + (
                              !isActive ? ' hover:text-primary-color ' : ' text-primary-color'
                            ))
                            }}   to='/blog'>
                                Blog
                                </NavLink>
                                
                            </div>
                          }
                        </div>
                        <div
                          className="relative inline-block"
                          onMouseEnter={handleMouseEnter2}
                          onMouseLeave={handleMouseLeave2}
                        >
                        <button className=" text-text-color1  font-[500px] text-[14px] px-4 py-2 rounded">
                        Help  {open2 ? <KeyboardArrowDownIcon/> :<KeyboardArrowUpIcon/>}
                          </button>
                          
                          {open2 && 
                            <div className="absolute xl:w-[120px] left-0 top-full bg-white border border-gray-300 p-4 shadow-md">
                            <NavLink className={({isActive}) =>{
                            return( ' text-text-color1 block my-3 font-[500] text-[14px]' + (
                              !isActive ? ' hover:text-primary-color ' : ' text-primary-color'
                            ))
                            }}   to='/faq'>
                                FAQ
                                </NavLink>
                                <NavLink className={({isActive}) =>{
                            return( ' text-text-color1 block my-3 font-[500] text-[14px]' + (
                              !isActive ? ' hover:text-primary-color ' : ' text-primary-color'
                            ))
                            }}   to='/contactus'>
                                Contact us
                                </NavLink>
                                
                            </div>
                          }
                        </div>
                        <Button2
                          name='Login'
                        /> 
                        <Button
                      name='Sign up'
                    />
                        </ul>
                          
                        </div>
                    </div>
                    
                </div>

                </div>
               
              
               
                </div>
                <Modal
        open={navbar}
       
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className='h-[95vh]  md:hidden'>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div className=" border-b-2 pb-2 px-4 pt-4 border-[#E6E6E7] flex justify-between">
            <div className=" mt-3">
            <img src={Logo}  className=" "  alt=" logo" />
            </div>
           
            <button
             className="p-2  text-white rounded-md outline-none bg-[#F5F7FA]  focus:border"
             onClick={() => setNavbar(!navbar)}
             >
            <svg
               xmlns="http://www.w3.org/2000/svg"
                 className="w-6 text-black h-6"
                   viewBox="0 0 20 20"
                  fill="currentColor"
                   >
                    <path
                     fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                      />
            </svg>
            </button>
            </div>
          </Typography>
          <div  className="  mt-2">
           <div className=" p-4">
           <div className=" flex mb-3 md:order-2">
                <div className=" flex  justify-between">
               
                </div>
                {/* <a href={Constant.BASE_LINK_URL + '/'} target='_blank' rel="noreferrer">
                <ThirdButton
                name=' Sign in'
               />
                </a> */}
               
               <div className=" px-3">
               {/* <a href={Constant.BASE_LINK_URL + '/signup'} target='_blank' rel="noreferrer">
                <Buttons
                    name='Create account'
                />
                </a> */}
               </div>

               
                </div>
                <div className="  flex justify-start">
                        <ul className="items-start justify-start space-y-8 md:flex md:space-x-6 md:space-y-0">
                      
                        <NavLink className={({isActive}) =>{
                            return( ' text-text-color1 block my-3 font-[500] text-[14px]' + (
                              !isActive ? ' hover:text-primary-color ' : ' text-primary-color'
                            ))
                            }}   to='/investment'>
                                Investment
                                </NavLink>
                                <NavLink className={({isActive}) =>{
                            return( ' text-text-color1 block my-3 font-[500] text-[14px]' + (
                              !isActive ? ' hover:text-primary-color ' : ' text-primary-color'
                            ))
                            }}   to='/assetsfinancing'>
                                Asset financing
                                </NavLink>
                                <NavLink className={({isActive}) =>{
                            return( ' text-text-color1 block my-3 font-[500] text-[14px]' + (
                              !isActive ? ' hover:text-primary-color ' : ' text-primary-color'
                            ))
                            }}   to='/savings'>
                                Savings
                                </NavLink>
                                <NavLink className={({isActive}) =>{
                            return( ' text-text-color1 block my-3 font-[500] text-[14px]' + (
                              !isActive ? ' hover:text-primary-color ' : ' text-primary-color'
                            ))
                            }}   to='/aboutus'>
                                About us
                                </NavLink>
                                <NavLink className={({isActive}) =>{
                            return( ' text-text-color1 block my-3 font-[500] text-[14px]' + (
                              !isActive ? ' hover:text-primary-color ' : ' text-primary-color'
                            ))
                            }}   to='/blog'>
                                Blog
                                </NavLink>
                                <NavLink className={({isActive}) =>{
                            return( ' text-text-color1 block my-3 font-[500] text-[14px]' + (
                              !isActive ? ' hover:text-primary-color ' : ' text-primary-color'
                            ))
                            }}   to='/faq'>
                                FAQ
                                </NavLink>
                                <NavLink className={({isActive}) =>{
                            return( ' text-text-color1 block my-3 font-[500] text-[14px]' + (
                              !isActive ? ' hover:text-primary-color ' : ' text-primary-color'
                            ))
                            }}   to='/contactus'>
                                Contact us
                                </NavLink>
                        </ul>
                          
                        </div>
                <div className=" mt-24">

                </div>
           </div>
          </div>
        </Box>
      </Modal>
            </div>
        </nav>


    );
}