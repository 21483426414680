import {BrowserRouter, Route,  Routes} from 'react-router-dom';
import Scrolltotop from './Component/Scrolltotop';
import Home from './Pages/Home';
import Investment from './Pages/Investment';
import AssetManangement from './Pages/AssestManagement';
import Savings from './Pages/Savings';
import Aboutus from './Pages/About';
import Blogs from './Pages/Blogs';
import Faq from './Pages/Faq';
import Contactus from './Pages/Contactus';
import Terms from './Pages/Terms';
import Privacy from './Pages/Privacy';
import NavBar from './Component/Navbar';
import Footer from './Component/Footer';


function App() {
  return (
    <div>
    <BrowserRouter>
      <Scrolltotop/>
      <NavBar/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/investment' element={<Investment/>}/>
        <Route path='/assetsfinancing' element={<AssetManangement/>}/>
        <Route path='/savings' element={<Savings/>}/>
        <Route path='/aboutus' element={<Aboutus/>}/>
        <Route path='/blog' element={<Blogs/>}/>
        <Route path='/faq' element={<Faq/>}/>
        <Route path='/contactus' element={<Contactus/>}/>
        <Route path='/terms' element={<Terms/>}/>
        <Route path='/privacy' element={<Privacy/>}/>
      </Routes>
      <Footer/>
    </BrowserRouter>
    </div>
  );
}

export default App;
