import React from 'react'
import Star from '../../Assets/images/star.svg'
import Constant from '../../Constant'
import T from '../../Assets/images/teamBg.png'
import MeetCom from './MeetCom'
function Meet() {
  return (
    <div className=' px-6 sm:px-24 py-16'>
      <div>
            <p className=' inline-block bg-white border p-3 text-[12px] border-primary-color4 font-[500] rounded-[32px] text-primary-color mb-4'>Meet the team <img src={Star} alt='Start' className=' inline'/>  </p>
      </div>
      <h1 className=' text-[20px] sm:text-[40px] text-text-color1 my-5 sm:w-[36%]'>A creative mind is a <span className={Constant.linearColor}>fertile land</span> for growth</h1>
      <p className=' mb-6 font-[500] text-[14px] sm:text-[20px] text-text-color1'>The right skills wielded by the right people to make anything possible.</p>
      <div className=' grid sm:grid-cols-2 md:grid-cols-3 gap-6'>
        <MeetCom
        img={T}
        name={"FREEBORN EHIRHERE"}
        role={"Development Team Lead, Lift Off"}
        />
         <MeetCom
        img={T}
        name={"FREEBORN EHIRHERE"}
        role={"Development Team Lead, Lift Off"}
        />
         <MeetCom
        img={T}
        name={"FREEBORN EHIRHERE"}
        role={"Development Team Lead, Lift Off"}
        />
         <MeetCom
        img={T}
        name={"FREEBORN EHIRHERE"}
        role={"Development Team Lead, Lift Off"}
        />
         <MeetCom
        img={T}
        name={"FREEBORN EHIRHERE"}
        role={"Development Team Lead, Lift Off"}
        />
         <MeetCom
        img={T}
        name={"FREEBORN EHIRHERE"}
        role={"Development Team Lead, Lift Off"}
        />
      </div>
    </div>
  )
}

export default Meet