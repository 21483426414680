import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Investment() {
   
  return (
    <div className=' px-6 sm:px-24 py-20 bg-primary-color5'>
        <div className=' sm:flex  gap-5'>
            <div>
                <h1 className=' text-[24px] text-primary-color font-[400] mb-4 sm:text-[44px]'>Investment</h1>
            </div>
            <div>
            <Accordion 
            elevation={0}
            style={{ backgroundColor: 'transparent' }}
            sx={{
            '&:before': {
                display: 'none',
                color: 'transparent'
            }
        }}
            >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className=' text-black'
        >
          <Typography><h1 className=' text-[16px] sm:text-[24px] font-[400] text-text-color1'>1. What is Islamic investment on Halal Wealth?</h1> </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p className=' text-[16px] sm:text-[24px]  text-text-color1 font-normal'>
          Islamic investment on Halal Wealth follows principles that align with Islamic finance, avoiding interest-based transactions and adhering to ethical guidelines.
          </p>
          </Typography>
        </AccordionDetails>
            </Accordion>
            <Accordion 
            elevation={0}
            style={{ backgroundColor: 'transparent' }}
            sx={{
            '&:before': {
                display: 'none',
                color: 'transparent'
            }
        }}
            >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className=' text-black'
        >
          <Typography><h1 className=' text-[16px] sm:text-[24px] font-[400] text-text-color1'>2. How do I start investing on Halal Wealth?</h1> </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p className=' text-[16px] sm:text-[24px]  text-text-color1 font-normal'>
          To start investing, log in to your account, choose the "Investment" section, and select an investment portfolio that matches your goals and risk tolerance. Follow the on-screen instructions to fund your investment.
          </p>
          </Typography>
        </AccordionDetails>
            </Accordion>
            <Accordion 
            elevation={0}
            style={{ backgroundColor: 'transparent' }}
            sx={{
            '&:before': {
                display: 'none',
                color: 'transparent'
            }
        }}
            >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className=' text-black'
        >
          <Typography><h1 className=' text-[16px] sm:text-[24px] font-[400] text-text-color1'>3. Can I withdraw my investments at any time?</h1> </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p className=' text-[16px] sm:text-[24px]  text-text-color1 font-normal'>
          Yes, you can withdraw funds from your investments at any time, subject to the terms and conditions of the specific investment portfolio you've chosen.
          </p>
          </Typography>
        </AccordionDetails>
            </Accordion>
            <Accordion 
            elevation={0}
            style={{ backgroundColor: 'transparent' }}
            sx={{
            '&:before': {
                display: 'none',
                color: 'transparent'
            }
        }}
            >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className=' text-black'
        >
          <Typography><h1 className=' text-[16px] sm:text-[24px] font-[400] text-text-color1'>4. What kind of returns can I expect from my investments?</h1> </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p className=' text-[16px] sm:text-[24px]  text-text-color1 font-normal'>
          Returns on investments vary depending on the portfolio's strategy and market conditions. You can find expected returns and risk profiles in the portfolio details.
          </p>
          </Typography>
        </AccordionDetails>
            </Accordion>
            </div>
        </div>
    </div>
  )
}

export default Investment