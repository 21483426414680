import React from 'react'
import Constant from '../../Constant'
import FaqCom from './FaqCom'
import { motion } from 'framer-motion';
import OverlayContainer from './OverlayCon';
import AccordionCard from './AccordionCard';

function Faq() {
  return (
    <div className=' mt-16 px-6 sm:px-24'>
        <h1 className=' my-7 text-[24px] sm:text-[44px] text-center text-text-color1 '>Frequently asked <span className={Constant.linearColor}>questions</span></h1>
       
        <div className="main-component">
        <div className="main-content -space-y-7 flex flex-col">
        <div className=' relative ' >
        <AccordionCard
           header={"What is halal wealth"}
           con={"Halal Wealth is an ethical investment platform that offers responsible savings, investment opportunities, and halal asset financing, all aligned with Islamic principles."}
          color={'bg-[#D5C1FB]'}
          textColor={'text-[#14013A]'}
          zIndex={1}
        />
        </div>
       
        <div className=' '>
        <AccordionCard
          header={"What is halal wealth"}
           con={"Halal Wealth is an ethical investment platform that offers responsible savings, investment opportunities, and halal asset financing, all aligned with Islamic principles."}
          color={'bg-[#14013A] '}
          textColor={'text-[#F2F1F3]'}
          zIndex={2}
        />
        </div>
        <div className=' '>
        <AccordionCard
          header={"What is halal wealth"}
           con={"Halal Wealth is an ethical investment platform that offers responsible savings, investment opportunities, and halal asset financing, all aligned with Islamic principles."}
          color={'bg-[#8BD8B4]'}
          textColor={'text-[#14013A]'}
          zIndex={3}
        />
        </div>
        <div className=' '>
        <AccordionCard
          header={"What is halal wealth"}
           con={"Halal Wealth is an ethical investment platform that offers responsible savings, investment opportunities, and halal asset financing, all aligned with Islamic principles."}
          color={'bg-[#FAB55B]'}
          textColor={'text-[#14013A]'}
          zIndex={3}
        />
        </div>
        <div className=' z-50 '>
        <FaqCom
          color={'bg-[#8046F2]'}
           textColor={'text-[#F2F1F3]'}
           header={"What is halal wealth"}
           con={"Halal Wealth is an ethical investment platform that offers responsible savings, investment opportunities, and halal asset financing, all aligned with Islamic principles."}
                />
        </div>
        
        </div>
        </div>
        {/* <div className='relative'>
            <div>
                <FaqCom
                    color={'bg-[#D5C1FB]'}
                    textColor={'text-[#14013A]'}
                    header={"What is halal wealth"}
                    con={"Halal Wealth is an ethical investment platform that offers responsible savings, investment opportunities, and halal asset financing, all aligned with Islamic principles."}
                />
            </div>
            <div className=' absolute top-24'>
                <FaqCom
                    color={'bg-[#14013A]'}
                    textColor={'text-[#F2F1F3]'}
                    header={"What is halal wealth"}
                    con={"Halal Wealth is an ethical investment platform that offers responsible savings, investment opportunities, and halal asset financing, all aligned with Islamic principles."}
                />
            </div>
            <div className=' absolute top-48'>
                <FaqCom
                    color={'bg-[#8BD8B4]'}
                    textColor={'text-[#14013A]'}
                    header={"What is halal wealth"}
                    con={"Halal Wealth is an ethical investment platform that offers responsible savings, investment opportunities, and halal asset financing, all aligned with Islamic principles."}
                />
            </div>
            <div className=' absolute top-[270px]'>
                <FaqCom
                    color={'bg-[#FAB55B]'}
                    textColor={'text-[#14013A]'}
                    header={"What is halal wealth"}
                    con={"Halal Wealth is an ethical investment platform that offers responsible savings, investment opportunities, and halal asset financing, all aligned with Islamic principles."}
                />
            </div>
            <div className=' z-50 relative'>
                <FaqCom
                    color={'bg-[#8046F2]'}
                    textColor={'text-[#F2F1F3]'}
                    header={"What is halal wealth"}
                    con={"Halal Wealth is an ethical investment platform that offers responsible savings, investment opportunities, and halal asset financing, all aligned with Islamic principles."}
                />
            </div>
        </div> */}
    </div>
  )
}

export default Faq