import React from 'react'
import M from '../../Assets/images/mFace.png'
import F from '../../Assets/images/fFace.png'
import Star from '../../Assets/images/star.svg'
function WordCom({type, con}) {
  return (
    <div className='border p-3 mb-10 sm:w-[50vh] border-[#E6DAFC] rounded-[12px] bg-[#F5F1FE] relative '>
        <div className=' flex'>
        <p className=' font-[500] text-[14px] text-text-color1'>{con}</p>
        {type=== 'f' &&
        <img src={Star} alt='star'/>
        }
        </div>
        {
            type === 'f' ?
            <img src={F} className=' absolute -top-8 -right-8' alt='female'/>:
            <img src={M} className=' absolute -bottom-8 -right-8' alt='male'/>
        }
       
    </div>
  )
}

export default WordCom