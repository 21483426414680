import React from 'react'
import Slider1 from './Slide1'
import Button from '../SelectValue/Button'
import Constant from '../../Constant'
import Slider2 from './Slider2'

function Sustain() {
  return (
    <div className=' bg-primary-color5 mt-20 p-6 md:p-24'>
    <div className=' md:hidden block'>
                <Slider2/>
            </div>
        <div className=' grid mb-8 md:mb-16 md:grid-cols-2 gap-5 '>
            <div className=' md:flex md:flex-col justify-center hidden '>
                <Slider2/>
            </div>
            <div className=' flex flex-col justify-center'>
                <div>
                    <h1 className=' font-normal text-text-color1 md:text-[44px] mb-4 text-[24px]'>Sustainable <span className={Constant.linearColor}>Savings for a Secure</span>  Future.</h1>
                    <p className=' font-normal text-text-color1 md:text-[18px] mb-4 text-[14px]'>At Halal wealth, we empower you to save with purpose. Our platform offers a range of ethical savings options that not only help you achieve your financial goals but also contribute to a more sustainable and responsible world.</p>
                    <p className=' font-normal text-text-color1 md:text-[18px] mb-4 text-[14px]'>Start your journey towards responsible savings today and make your money work for a meaningful cause</p>

                    <Button
                        name='Learn more'
                    />
                </div>
            </div>
        </div>
        <div className=' grid mt-8 md:grid-cols-2 gap-6 '>
            <div className=' flex flex-col justify-center'>
            <h1 className=' font-normal text-text-color1 md:text-[44px] mb-4 text-[24px]'>Fueling Your Aspirations with <span className={Constant.linearColor}>Ethical Asset Financing</span> </h1>
            <p className=' font-normal text-text-color1 md:text-[18px] mb-4 text-[14px]'>With our ethical asset financing options, you can access the items you need while adhering to your principles. Say goodbye to interest fees, as we offer installment plans that align with your financial well-being. Whether it's technology, equipment, or other essentials, we're here to help you achieve your dreams responsibly.</p>
            <p className=' font-normal text-text-color1 md:text-[18px] mb-4 text-[14px]'>Join us in a financial journey that values both your ambitions and your ethical beliefs. Explore our range of asset financing opportunities and experience a new standard of responsible support.</p>
            <div>
            <Button
             name='Learn more'
             />
            </div>
           
            </div>
            <div className=' md:flex md:flex-col justify-center hidden'>
                <Slider1/>
            </div>
        </div>
        <div className=' mt-5 md:hidden block'>
                <Slider1/>
            </div>
    </div>
  )
}

export default Sustain