import React from 'react'

function Content() {
  return (
    <div className=' px-6 sm:px-24 py-20'>
    <div className=' my-5'>
        <p className=' text-[#5C556C] font-[500] text-[16px] sm:text-[18px]'>Welcome to Halal Wealth, a wealth management solution operating in accordance with Islamic non-interest financing principles. Please read these Terms of Service carefully before using our platform.
        <br></br><br></br>
        By accessing or using Halal Wealth, you agree to comply with and be bound by these Terms of Service. If you do not agree with these terms, please do not use our platform.
        </p>
    </div>
    <div className=' mb-9'>
        <p className=' font-[600] text-[18px] text-primary-color mb-4 sm:text-[20px]'> 1. Acceptance of Terms</p>
        <p className=' text-[#5C556C] font-[500] text-[16px] sm:text-[18px]'>By accessing or using Halal Wealth, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service and our Privacy Policy.</p>
    </div>
    <div className=' mb-9'>
    <p className=' font-[600] text-[18px] text-primary-color mb-4 sm:text-[20px]'> 2. Eligibility</p>
    <p className=' text-[#5C556C] font-[500] text-[16px] sm:text-[18px]'>You must be at least [age] years old and have the legal capacity to enter into a binding agreement to use Halal Wealth. By using our platform, you represent and warrant that you meet these eligibility criteria.</p>
    </div>
    <div>
    <div className=' mb-9'>
    <p className=' font-[600] text-[18px] text-primary-color mb-4 sm:text-[20px]'>3. User Accounts</p>
    <ul className="list-disc list-inside">
                    <li className='text-[#5C556C] sm:text-[20px] '><span className=' font-bold'>Registration:</span> To use certain features of Halal Wealth, you may be required to create a user account. You are responsible for providing accurate and up-to-date information during the registration process.
                            </li>
                    <li className='text-[#5C556C] sm:text-[20px] '> <span className=' font-bold'>Security:</span> You are responsible for maintaining the security of your account and password. Do not share your login credentials with others. Notify us immediately if you suspect unauthorized access to your account.
                            </li>
                    <li className=' text-[#5C556C] sm:text-[20px]'><span className=' font-bold'>Termination:</span>  We reserve the right to terminate or suspend your account, with or without notice, for any violation of these Terms or for any other reason deemed necessary.
                        </li>
                   
                </ul>
    </div>
    <div className=' mb-9'>
        <p className=' font-[600] text-[18px] text-primary-color mb-4 sm:text-[20px]'>4. Use of Halal Wealth</p>
        <ul className="list-disc list-inside">
                    <li className='text-[#5C556C] sm:text-[20px] '><span className=' font-bold'>Investments:</span> Halal Wealth offers ethical investment opportunities that adhere to Islamic finance principles. You understand that all investments carry risks, and past performance is not indicative of future results.
                            </li>
                    <li className='text-[#5C556C] sm:text-[20px] '> <span className=' font-bold'>Savings:</span> Our savings feature allows you to set financial goals and save ethically. You are solely responsible for setting and managing your savings goals.
                            </li>
                    <li className=' text-[#5C556C] sm:text-[20px]'><span className=' font-bold'>Content:</span>  You agree not to use Halal Wealth for any unlawful or prohibited purposes. You are responsible for all content you submit on our platform, and you must not violate any applicable laws or regulations.
                        </li>
                   
                </ul>
    </div>
    <div className=' mb-9'>
    <p className=' font-[600] text-[18px] text-primary-color mb-4 sm:text-[20px]'>  5. Privacy</p>
    <p className=' text-[#5C556C] font-[500] text-[16px] sm:text-[18px]'>Your use of Halal Wealth is also governed by our Privacy Policy. By using our platform, you consent to the collection, use, and sharing of your information as described in the Privacy Policy.</p>
    </div>
    <div className=' mb-9'>
    <p className=' font-[600] text-[18px] text-primary-color mb-4 sm:text-[20px]'>6. Modifications</p>
    <p className=' text-[#5C556C] font-[500] text-[16px] sm:text-[18px]'>We reserve the right to modify these Terms of Service at any time. We will notify users of any material changes, and your continued use of Halal Wealth after such changes constitutes acceptance of the modified terms.</p>
    </div>
    <div className=' mb-9'>
    <p className=' font-[600] text-[18px] text-primary-color mb-4 sm:text-[20px]'>7. Termination</p>
    <p className=' text-[#5C556C] font-[500] text-[16px] sm:text-[18px]'>We reserve the right to terminate or suspend your access to Halal Wealth at our discretion, without notice and for any reason, including if you violate these Terms of Service.</p>
    </div>
    <div className=' mb-9'>
    <p className=' font-[600] text-[18px] text-primary-color mb-4 sm:text-[20px]'>8. Disclaimers</p>
    <p className=' text-[#5C556C] font-[500] text-[16px] sm:text-[18px]'>Halal Wealth provides financial information and services based on ethical principles but does not provide personalized financial advice. All financial decisions are made at your own risk.
</p>
    </div>
    <div className=' mb-9'>
    <p className=' font-[600] text-[18px] text-primary-color mb-4 sm:text-[20px]'>9. Limitation of Liability</p>
    <p className=' text-[#5C556C] font-[500] text-[16px] sm:text-[18px]'>Halal Wealth and its affiliates are not liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of our platform.
</p>
    </div>
    <div className=' mb-9'>
    <p className=' font-[600] text-[18px] text-primary-color mb-4 sm:text-[20px]'>10. Governing Law</p>
    <p className=' text-[#5C556C] font-[500] text-[16px] sm:text-[18px]'>These Terms of Service are governed by and constructed in accordance with the laws of the Central Bank of Nigeria (CBN), Securities and Exchange Commission (SEC), Money Laundering (Prohibition) Act, Nigeria Data Protection Regulation (NDPR) and Islamic Finance regulations . Any disputes arising from or relating to these terms will be subject to the exclusive jurisdiction of the courts in [Jurisdiction].
</p>
    </div>
    <div className=' mb-9'>
    <p className=' font-[600] text-[18px] text-primary-color mb-4 sm:text-[20px]'>11. Contact Information</p>
    <p className=' text-[#5C556C] font-[500] text-[16px] sm:text-[18px]'>For questions or concerns regarding these Terms of Service, please contact us at [Contact Email].
</p>
    </div>
    </div>
    </div>
  )
}

export default Content