import React from 'react'
import BenefitCom from './BenefitCom'

function Benefit() {
  return (
    <div className=' px-6 sm:px-24 py-10 sm:py-20 bg-text-gray'>
        <div>
            <h1 className=' text-[24px] sm:text-[44px] text-text-color1 mb-7'>Enjoy some of our benefits</h1>
            <div className=' grid sm:grid-cols-2 gap-5 '>
            <BenefitCom
                color={" bg-[#E6DAFC]"}
                header={"Interest-Free Installments"}
                con={"Ethical savings allow you to put your money to work for causes you believe in. By choosing responsible and sustainable savings options, you contribute to projects that have a positive impact on the environment, social well-being, and community development."}
            />
             <BenefitCom
                color={" bg-[#D1F0E1]"}
                header={"Flexible Payment Plans"}
                con={"Tailor your payments to suit your financial situation. Choose from a range of flexible installment plans that align with your budget and schedule."}
            />
            <div className='hidden sm:block'>
            <BenefitCom
                color={" bg-[#D1F0E1]"}
                header={"Ethical Financing"}
                con={"Embrace a responsible approach to financing. Our interest-free installment options reflect our commitment to ethical and fair financial practices, giving you a stress-free way to acquire the items you desire."}
            />
            </div>
            
            <BenefitCom
                color={" bg-[#E6DAFC]"}
                header={"Transparent Terms"}
                con={"We believe in transparency. Our installment plans come with clear terms and conditions, so you know exactly what to expect. No hidden fees or surprises – just straightforward, interest-free financing for your purchases."}
            />
            <div className='sm:hidden block'>
            <BenefitCom
                color={" bg-[#D1F0E1]"}
                header={"Ethical Financing"}
                con={"Embrace a responsible approach to financing. Our interest-free installment options reflect our commitment to ethical and fair financial practices, giving you a stress-free way to acquire the items you desire."}
            />
            </div>
            </div>
        </div>
    </div>
  )
}

export default Benefit