import React from 'react'
import Star from '../../Assets/images/star.svg'
import ContactForm from './ContactForm'

function Topsec() {
  return (
    <div className=' bg-primary-color5 '>
    <div className=' pt-[140px] pb-20  sm:pt-[177px] '>
        <div className=' flex mx-auto px-6 sm:px-0 sm:w-[75%] md:w-[50%] justify-center'>
            <div>
            <div className=' flex justify-center'>
            <p className=' inline-block text-center bg-white p-3 text-[12px] font-[500] rounded-[32px] text-primary-color mb-4'> Contact us <img src={Star} alt='Start' className=' inline'/> </p>
             </div>
             <h1 className=' text-[24px] mb-6 font-[24px] text-center text-text-color1 sm:text-[48px]'>Get in touch with us</h1>
             <p className=' text-[14px] mb-10 font-[500] text-center text-text-color1  sm:text-[20px]'>Need support? Have a question? Running into a problem? We’re here to help.</p>
            </div>
        </div>
        <div className='  px-6 sm:px-24'>
        
       <ContactForm/>
       
        </div>
    </div>
   
    </div>
  )
}

export default Topsec