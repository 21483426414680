// import React from 'react'
// import HowInvestCom from './HowInvestCom'

// function HowInvest() {
//   return (
//     <div className=' mt-32'>
//         <HowInvestCom/>
//     </div>
//   )
// }

// export default HowInvest

import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import F1 from "../../Assets/images/fa1.png"
import F2 from "../../Assets/images/fa2.png"
import F3 from "../../Assets/images/fa3.png"

function SampleNextArrow(props) {
  return (
    <div 
    />
  );
}

function SamplePrevArrow(props) {
  return (
    <div
    />
  );
}

export default class Slider1 extends Component {
    constructor(props) {
      super(props);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
    }
    next() {
      this.slider.slickNext();
    }
    previous() {
      this.slider.slickPrev();
    }
    render() {
      const settings = {
        
        fade: true,
        infinite: true,
        autoplay: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
      return (
        <div className=" ">
       
          <Slider ref={c => (this.slider = c)} {...settings}>
            <div  key={1}>
            <img src={F1} alt="first "/>
            </div>
            <div  key={2}>
            <img src={F2} alt="Second"/>
            </div>
            <div  key={3}>
            <img src={F3} alt="third"/>
            </div>
          </Slider>
        
        </div>
      );
    }
  }
