import React from 'react'
import C1 from '../../Assets/images/co1.svg'
import C2 from '../../Assets/images/co2.svg'
import C3 from '../../Assets/images/co3.svg'
import C4 from '../../Assets/images/co4.svg'
import CoreComponent from './CoreComponent'
function Core() {
  return (
    <div className=' px-6 sm:px-24 bg-text-gray py-16 '>
        <h1 className=' text-[24px] text-text-color1 mb-5 sm:text-[44px]'>Our core values</h1>
        <p className=' sm:w-[80%] text-text-color1 text-[14px] sm:text-[20px] font-medium'>Our values define the Halal wealth culture, who we are, and what we do every day. They are the foundation of our identity and the compass of our interaction with all stakeholders: customers, regulators, investors, partners and our communities.</p>
        <div className=' grid sm:grid-cols-2 gap-5 mt-7'>
            <CoreComponent
                image={C1}
                color={"bg-[#ECF9F3]"}
                header={"Simplicity & Empathy"}
                con={"We create simple but powerful experiences because greatness is simple. We see with the eyes of our customers, listen with their ears and feel with their hearts."}
            />
             <CoreComponent
                image={C2}
                color={"bg-[#F5F1FE]"}
                header={"We’re building together"}
                con={"We create simple but powerful experiences because greatness is simple. We see with the eyes of our customers, listen with their ears and feel with their hearts."}
            />
            <CoreComponent
                image={C3}
                color={"bg-[#F5F1FE]"}
                header={"Our way with words"}
                con={"How we communicate changes how people feel about us, so we’d better make sure our words reflect what we stand for. Our tone of voice guidelines exist to do just that."}
            />
             <CoreComponent
                image={C4}
                color={"bg-[#ECF9F3]"}
                header={"Personal Growth & Development"}
                con={"Our growth as a company is a product of the growth of our individual team members. Grow daily. It compounds. Do your best work, daily."}
            />
        </div>
    </div>
  )
}

export default Core