import React from 'react'
import Constant from '../../Constant'
import GraphCom from './GraphCom'

function TopInvestment() {
  return (
    <div className=' px-6 sm:px-24 py-16'>
    <h1 className=' text-[24px] sm:text-[44px] text-text-color1 font-[400] mb-4 sm:w-[45%]'>Enjoy some of our <span className={Constant.linearColor}>top investment</span> opportunities</h1>
    <div className=' my-16 flex sm:gap-5'>
      <p className=' inline-block font-[500] p-2 border border-[#E6DAFC] bg-[#F5F1FE] rounded-[32px] text-[14px] text-primary-color'>SUKUK BONDS</p>
      <p className='inline-block font-[500] p-2 text-[#5C556C] text-[14px]'>ETHICAL INDEX</p>
      <p className='inline-block font-[500] p-2 text-[#5C556C] text-[14px]'>MURABAHA</p>
    </div>
    <div className=' grid mt-16 sm:grid-cols-2 md:grid-cols-3 gap-5'>
    <GraphCom
      name={"Lotus Halal Fixed Income Fund"}
      start={"1.12"}
      end={"3.22"}
    />
    <GraphCom
      name={"Lotus Halal Fixed Income Fund"}
      start={"1.12"}
      end={"3.22"}
    />
    <GraphCom
      name={"Lotus Halal Fixed Income Fund"}
      start={"1.12"}
      end={"3.22"}
    />
    <GraphCom
      name={"Lotus Halal Fixed Income Fund"}
      start={"1.12"}
      end={"3.22"}
    />
    <GraphCom
      name={"Lotus Halal Fixed Income Fund"}
      start={"1.12"}
      end={"3.22"}
    />
    <GraphCom
      name={"Lotus Halal Fixed Income Fund"}
      start={"1.12"}
      end={"3.22"}
    />
    </div>
    </div>
  )
}

export default TopInvestment