import React from 'react'
import Star from '../../Assets/images/star.svg'
import Constant from '../../Constant'
import A1 from '../../Assets/images/abo1.png'
import A2 from '../../Assets/images/abo2.png'
import A3 from '../../Assets/images/abo3.png'
import A4 from '../../Assets/images/abo4.png'
import A5 from '../../Assets/images/abo5.png'
import A6 from '../../Assets/images/abo6.png'
import As1 from '../../Assets/images/abs1.png'
import As2 from '../../Assets/images/abs2.png'
import As3 from '../../Assets/images/abs3.png'
import As4 from '../../Assets/images/abs4.png'
import As5 from '../../Assets/images/abs5.png'
import As6 from '../../Assets/images/abs6.png'
function TopSec() {
  return (
    <div className=' body-bg '>
    <div className=' pt-[140px] pb-20  sm:pt-[177px] '>
        <div className=' flex mx-auto px-6 sm:px-0 sm:w-[80%] md:w-[60%] justify-center'>
            <div>
            <div className=' flex justify-center'>
            <p className=' inline-block text-center bg-white p-3 text-[12px] font-[500] rounded-[32px] text-primary-color mb-4'> <img src={Star} alt='Start' className=' inline'/> Empowering Ethics, Enriching Futures. </p>
             </div>
             <h1 className=' text-[24px] mb-6 font-[24px] text-center text-text-color1 sm:text-[48px]'>Redefining the <span className={Constant.linearColor}>Landscape of Ethical</span> Finance</h1>
             <p className=' text-[14px] mb-10 font-[500] text-text-color1  sm:text-[20px]'>We are committed to redefining the landscape of ethical finance. Through innovative solutions, we empower individuals to invest, save, and finance responsibly, fostering a positive impact on both personal growth and global well-being</p>
            </div>
        </div>
        <div className='  px-3 '>
        
        <div className=' hidden sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4 mt-8'>
            <div className=' flex justify-between flex-col'>
                <img src={A1} alt=' Pict'/>
                <img src={A2} alt=' Pict'/>
            </div>
            <div>
            <img src={A3} alt=' Pict'/>
            </div>
            <div className=' flex justify-between flex-col'>
            <img src={A4} alt=' Pict'/>
                <img src={A5} alt=' Pict'/>
            </div>
            <div>
            <img src={A6} alt=' Pict'/>
            </div>
        </div>
        <div className=' grid grid-cols-2 gap-4 sm:hidden'>
            <img src={As1} className=' w-full' alt='Faces'/>
            <img src={As2} className=' w-full' alt='Faces'/>
            <div className=' col-span-2'>
            <img src={As3} className=' w-full' alt='Faces'/>
            </div>
            <img src={As4} className=' w-full' alt='Faces'/>
            <img src={As5} className=' w-full' alt='Faces'/>
            <div className=' col-span-2'>
            <img src={As6} className=' w-full' alt='Faces'/>
            </div>
        </div>
        </div>
    </div>
   
    </div>
  )
}

export default TopSec