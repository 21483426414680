import React, { useState } from 'react'
import ContactCom from './ContactCom'
import LocationMap from './Mapsam'
import LocationMap2 from './Mapsam2'
function Contactuss() {
  const [lagosC, setLagosC] = useState(true)
   const [illorin, setIllorinC] = useState(false)
   const handleChangeIllorin = () =>{
    setIllorinC(true)
     setLagosC(false)
   
   }
   const handleChangeLagos = () =>{
     setIllorinC(false)
    setLagosC(true)
    
  }
  // if (!isLoaded) return <div>Loading...</div>;

  return <div className=' px-6 sm:px-24 py-20 '> 
 
  <div className=' wow  grid sm:grid-cols-5  gap-4  animate__animated animate__zoomIn'>
    <div>
  <h1 className=' text-[24px] sm:text-[32px] font-[32px]'>Our locations</h1>
    </div>
    <div className=' sm:col-span-3'>
    <div  className=' sm:flex gap-5'>
    <ContactCom
      name='Lagos'
      address=' No 91 Ogodu road, Ojota Lagos.'
      email='hello@zouse.co'
      pNum='08123945301, 09082171728'
     
      bool={lagosC}
      change = {handleChangeLagos}
    />
     <ContactCom
      name='Kwara'
      address='121 Olanrewaju Building Muritala way  Post Office Road, Ilorin, Kwara State, Nigeria'
      email='hello@zouse.co'
      pNum='08171299359, 09082171728'
      bool={illorin}
      change = {handleChangeIllorin}
    />
       
   
  </div>
  </div>
 
  </div>
  <div className='   w-full h-[400px]  mx-auto'>
  {/* <Map
    area={lagosC ? { lat: 6.578976, lng: 3.38542 }: { lat: 8.485017, lng: 4.563801 }}
  /> */}
  {lagosC ? 
  <LocationMap latitude={6.578976} longitude={3.38542} />:
  <LocationMap2 latitude={8.485017} longitude={4.563801} />
  }
  {/* <LocationMap latitude={latitude} longitude={longitude} /> */}
  </div>
  </div>
  
  
}

export default Contactuss