import React from 'react'
import Bg from '../../Assets/images/saveS.png'
import Bgs from '../../Assets/images/saveB.png'
import Star from '../../Assets/images/star.svg'
import Constant from '../../Constant'
import I1 from '../../Assets/images/si1.svg'
import I2 from '../../Assets/images/si2.png'
import I3 from '../../Assets/images/si3.png'
import Tcom from './Tcom'
function TopSec() {
  return (
    <div className=' body-bg '>
    <div className=' pt-[140px] sm:pt-[177px] grid md:grid-cols-3 gap-4'>
    <div className=' md:col-span-2 mb-10  px-6 sm:px-24'>
    <div>
    <div>
            <p className=' inline-block bg-white p-3 text-[12px] font-[500] rounded-[32px] text-primary-color mb-4'> <img src={Star} alt='Start' className=' inline'/> Empowering Ethics, Enriching Futures. </p>
      </div>
      <h1 className=' text-[24px] mb-6 font-[24px] text-text-color1 sm:text-[48px]'>Nurture your <span className={Constant.linearColor}>savings</span> through Halal Wealth's offerings. </h1>
    <p className=' text-[14px] mb-10 font-[500] text-text-color1  sm:text-[20px]'>By choosing our Ethical Savings Plans, you're not only securing your financial future but also contributing to causes that matter. We curate options that align with ethical and sustainable practices, ensuring your money is part of positive change.</p>
    <div >
    
    </div>
    </div>
    </div>
    <div className='px-10 sm:pt-20 relative'>
    <img src={Bg}  alt='home' className=' hidden md:block'/>
    <img src={Bgs}  alt='home' className='md:hidden block'/>
    <div className=' absolute top-0 left-16 sm:top-20 sm:left-20'>
       <Tcom
        img={I1}
        con="Eid savings"
       /> 
    </div>
    <div className=' absolute top-0 sm:top-48 right-5'>
       <Tcom
        img={I2}
        con="Nikkah savings"
       /> 
    </div>
    <div className=' absolute bottom-2 right-4 sm:left-16'>
       <Tcom
        img={I3}
        con="Nikkah savings"
       /> 
    </div>
    </div>
    </div>
   
    </div>
  )
}

export default TopSec