import React from 'react'
import Star from '../../Assets/images/star.svg'
import Constant from '../../Constant'
import WordCom from './WordCom'
import Starr from '../../Assets/images/starr.svg'
function Word() {
  return (
    <div className=' px-6 bg-text-gray py-6 sm:py-24 sm:px-24'>
        <div className=' mx-auto flex justify-center'>
        <div>
        <div className=' mx-auto flex justify-center'>
        <p className=' text-center inline-block p-2 font-[500] text-primary-color text-[14px] border border-[#E6DAFC] shadow rounded-[36px]'>
            <img src={Star} alt='Start' className=' inline'/>Take their word, Not ours 
            </p>
        </div>
        
            <h1 className='my-5 text-[24px] text-center mb-12  sm:text-[44px] font-[400] '>Words <span className={Constant.linearColor}>from our satisfied</span>  customers</h1>
        </div>
            
        </div>
        <div className=' hidden sm:flex sm:mb-16 sm:gap-4 sm:justify-between'>
        <div>

        </div>
       
        <WordCom
            type='f'
            con="Halal Wealth helped me invest confidently in alignment with my values. It's more than finance; it's a movement."
        />
        <div className='hidden sm:flex sm:flex-col sm:justify-end'>
            <img src={Starr} alt='star'/>
        </div>
        <div>

        </div>
        <WordCom
            type='m'
            con="Ethical investing made simple! Halal Wealth's platform is user-friendly and impactful"
        />
         <div>
            
            </div>
        </div>
        <div className='hidden sm:flex sm:mb-16 sm:gap-4 sm:justify-evenly'>
        <div></div>
        <div></div>
        <WordCom
            type='m'
            con="Ethical investing made simple! Halal Wealth's platform is user-friendly and impactful"
        />
        <div>

        </div>
        <div>

        </div>
        <WordCom
            type='f'
            con="Halal Wealth helped me invest confidently in alignment with my values. It's more than finance; it's a movement."
        />
         <div></div>
         <div></div>
        </div>
        <div className='hidden sm:flex sm:mb-4 sm:gap-4 sm:justify-between'>
        <WordCom
            type='f'
            con="Halal Wealth helped me invest confidently in alignment with my values. It's more than finance; it's a movement."
        />
        <div>

        </div>
        <div>

        </div>
        <WordCom
            type='m'
            con="Ethical investing made simple! Halal Wealth's platform is user-friendly and impactful"
        />
        </div>
        <div className=' block sm:hidden'>
        <WordCom
            type='f'
            con="Halal Wealth helped me invest confidently in alignment with my values. It's more than finance; it's a movement."
        />
        <WordCom
            type='m'
            con="Ethical investing made simple! Halal Wealth's platform is user-friendly and impactful"
        />
         <WordCom
            type='f'
            con="Halal Wealth helped me invest confidently in alignment with my values. It's more than finance; it's a movement."
        />
        <WordCom
            type='m'
            con="Ethical investing made simple! Halal Wealth's platform is user-friendly and impactful"
        />
         <WordCom
            type='f'
            con="Halal Wealth helped me invest confidently in alignment with my values. It's more than finance; it's a movement."
        />
        <WordCom
            type='m'
            con="Ethical investing made simple! Halal Wealth's platform is user-friendly and impactful"
        />
        </div>
    </div>
  )
}

export default Word