import React from 'react'

function Content() {
  return (
    <div className=' px-6 sm:px-24 py-20'>
    <div className=' my-5'>
        <p className=' text-[#5C556C] font-[500] text-[16px] sm:text-[18px]'>Welcome to Halal Wealth, a wealth management solution operating in accordance with Islamic non-interest financing principles. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our platform. Please read this Privacy Policy carefully.
</p>
    </div>
    <div className=' mb-9'>
        <p className=' font-[600] text-[18px] text-primary-color mb-4 sm:text-[20px]'>1. Information We Collect</p>
        <p className=' text-[#5C556C] font-[500] text-[16px] sm:text-[18px]'>We may collect the following types of information when you use Halal Wealth:</p>
        <ul className="list-disc list-inside">
                    <li className='text-[#5C556C] sm:text-[20px] '><span className=' font-bold'>Personal Information:</span> This includes your name, email address, phone number, date of birth, and other information you provide when creating an account or using our platform.
                            </li>
                    <li className='text-[#5C556C] sm:text-[20px] '> <span className=' font-bold'>Financial Information:</span> We may collect information related to your financial activities, such as transaction data, investment preferences, savings goals, and asset financing requests.
                            </li>
                    <li className=' text-[#5C556C] sm:text-[20px]'><span className=' font-bold'>Payment Information:</span> If you make payments on our platform, we may collect payment card information or other payment details.
                        </li>
                    <li className=' text-[#5C556C] sm:text-[20px]'><span className=' font-bold'>Usage Information:</span> We gather information about how you use Halal Wealth, including your interactions with our features and services.
                            </li>
                    <li className=' text-[#5C556C] sm:text-[20px]'><span className=' font-bold'>Device and Log Information:</span> We collect device information, such as your IP address, browser type, and operating system. We also log usage data and analytics to improve our platform.</li>
                   
                </ul>
    </div>
    <div className=' mb-9'>
    <p className=' font-[600] text-[18px] text-primary-color mb-4 sm:text-[20px]'>2. How We Use Your Information</p>
    <p className=' text-[#5C556C] font-[500] text-[16px] sm:text-[18px]'>We use your information for the following purposes:</p>
    <ul className="list-disc list-inside">
                    <li className='text-[#5C556C] sm:text-[20px] '><span className=' font-bold'>Account Creation and Management:</span> To create and manage your user account, provide you with access to our features, and communicate with you regarding your account.
                            </li>
                    <li className='text-[#5C556C] sm:text-[20px] '> <span className=' font-bold'>Financial Services:</span> To process transactions, investments, savings, and asset financing requests in accordance with your instructions.
                            </li>
                    <li className=' text-[#5C556C] sm:text-[20px]'><span className=' font-bold'>Communication:</span>  To respond to your inquiries, provide customer support, and send transaction confirmations and updates.
                        </li>
                    <li className=' text-[#5C556C] sm:text-[20px]'><span className=' font-bold'>Improvement:</span> To analyze user behavior, gather insights, and improve our platform, services, and user experience.
                            </li>
                   
                </ul>
    </div>
    <div>
    <div className=' mb-9'>
    <p className=' font-[600] text-[18px] text-primary-color mb-4 sm:text-[20px]'>3. Information Sharing</p>
    <p className=' text-[#5C556C] font-[500] text-[16px] sm:text-[18px]'>We may share your information in the following circumstances:</p>
    <ul className="list-disc list-inside">
                    <li className='text-[#5C556C] sm:text-[20px] '><span className=' font-bold'>Service Providers:</span> We may share your information with service providers who assist us in providing our services, including payment processors, analytics providers, and customer support services.
                            </li>
                    <li className='text-[#5C556C] sm:text-[20px] '> <span className=' font-bold'>Legal Requirements:</span> We may disclose your information if required by law, legal process, or government authorities.
                            </li>
                    <li className=' text-[#5C556C] sm:text-[20px]'><span className=' font-bold'>Business Transactions:</span>  In the event of a merger, acquisition, or sale of assets, your information may be transferred to a new entity.
                        </li>
                   
                </ul>
    </div>
    <div className=' mb-9'>
    <p className=' font-[600] text-[18px] text-primary-color mb-4 sm:text-[20px]'> 4. Your Choices</p>
    <p className=' text-[#5C556C] font-[500] text-[16px] sm:text-[18px]'>You have the following choices regarding your information:</p>
    <ul className="list-disc list-inside">
                    <li className='text-[#5C556C] sm:text-[20px] '><span className=' font-bold'>Account Information: </span> You can review and update your account information by logging into your Halal Wealth account.
                            </li>
                    <li className='text-[#5C556C] sm:text-[20px] '> <span className=' font-bold'>Communication:</span> You can opt-out of receiving certain communications from us by following the unsubscribe instructions in the email or contacting us directly.
                            </li>
                    <li className=' text-[#5C556C] sm:text-[20px]'><span className=' font-bold'>Data Access and Deletion:</span>  You can request access to your personal information and request its deletion by contacting us.
                        </li>
                   
                </ul>
    </div>
    <div className=' mb-9'>
    <p className=' font-[600] text-[18px] text-primary-color mb-4 sm:text-[20px]'>5. Security</p>
    <p className=' text-[#5C556C] font-[500] text-[16px] sm:text-[18px]'>We take security seriously and employ reasonable security measures to protect your information from unauthorized access, disclosure, alteration, or destruction.</p>
    </div>
    <div className=' mb-9'>
    <p className=' font-[600] text-[18px] text-primary-color mb-4 sm:text-[20px]'>6. Changes to this Privacy Policy</p>
    <p className=' text-[#5C556C] font-[500] text-[16px] sm:text-[18px]'>We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the updated Privacy Policy on our platform.</p>
    </div>
    <div className=' sm:mb-10'>
        <p className="text-primary-color  sm:text-[20px] font-bold leading-loose tracking-wide">7. Contact Us</p>
        <div>
            <p className='mt-3 text-[#5C556C] sm:text-[20px]'>If you have questions or concerns about this Privacy Policy or our data practices, please contact us at [Contact Email].</p>
        </div>
        </div>
    </div>
    </div>
  )
}

export default Content