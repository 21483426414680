import React from 'react'
import Circle from '../../Assets/images/wc.svg'
function WhyComponent({header, content}) {
  return (
    <div>
        <div className=' mb-3'>
            <img src={Circle} alt='circle'/>
        </div>
        <h1 className=' text-[20px] mb-4 font-normal text-text-color1 '>{header}</h1>
        <p className='text-[16px] mb-4 font-[500] text-text-color1'>{content}</p>
    </div>
  )
}

export default WhyComponent