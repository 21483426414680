import React from 'react'
import Topsec from '../../Component/Terms/Topsec'
import Content from '../../Component/Terms/Content'

function Terms() {
  return (
    <div>
      <Topsec/>
      <Content/>
    </div>
  )
}

export default Terms