import React from 'react'
import Circle from '../../Assets/images/wc.svg'
function BenefitCom({color, header, con}) {
  return (
    <div className={` p-4 px-5 border mb-5 rounded-lg ${color}`}>
   <div className=' mb-3'>
            <img src={Circle} alt='circle'/>
        </div>
        <h1 className=' text-[20px] mb-4 font-normal text-text-color1 '>{header}</h1>
        <p className='text-[18px] mb-4 font-[500] text-text-color1'>{con}</p>
    </div>
  )
}

export default BenefitCom