import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Savings() {
   
  return (
    <div className=' px-6 sm:px-24 py-20 '>
        <div className=' sm:flex  gap-5'>
            <div>
                <h1 className=' text-[24px] text-primary-color font-[400] mb-4 sm:text-[44px]'>Savings</h1>
            </div>
            <div>
            <Accordion 
            elevation={0}
            style={{ backgroundColor: 'transparent' }}
            sx={{
            '&:before': {
                display: 'none',
                color: 'transparent'
            }
        }}
            >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className=' text-black'
        >
          <Typography><h1 className=' text-[16px] sm:text-[24px] font-[400] text-text-color1'>1. How does the savings feature work on Halal Wealth?</h1> </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p className=' text-[16px] sm:text-[24px]  text-text-color1 font-normal'>
          Halal Wealth offers a range of savings options, including target savings plans, custom savings goals, and flexible savings. Users can choose the option that suits their financial goals.
          </p>
          </Typography>
        </AccordionDetails>
            </Accordion>
            <Accordion
            elevation={0}
            style={{ backgroundColor: 'transparent' }}
            >
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                >
                <Typography><h1 className=' text-[16px] sm:text-[24px] font-[400] text-text-color1'>2. Are my savings on Halal Wealth secure?</h1> </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                <p className=' text-[16px] sm:text-[24px]  text-text-color1 font-normal'>
                Yes, your savings are secure. Halal Wealth adheres to strict security measures to protect your financial data and savings.
                </p>
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion
            elevation={0}
            style={{ backgroundColor: 'transparent' }}
            >
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                >
                <Typography><h1 className=' text-[16px] sm:text-[24px] font-[400] text-text-color1'>3. Can I withdraw funds from my savings before reaching my goal?</h1> </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                <p className=' text-[16px] sm:text-[24px]  text-text-color1 font-normal'>
                Yes, you can withdraw funds from your savings at any time, particularly with the flexible savings option. However, some target savings plans may have specific withdrawal rules.
                </p>
                </Typography>
                </AccordionDetails>
            </Accordion>
            </div>
        </div>
    </div>
  )
}

export default Savings