import React from 'react'
import Circle from '../../Assets/images/cs.png'
import Graph from '../../Assets/images/graph.png'
import Button from '../SelectValue/Button'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
function GraphCom({name, start, end}) {
  return (
    <div className=' shadow-lg p-4 border mb-5  rounded-lg bg-white'>
        <div className=' flex mb-4 gap-5'>
        <img src={Circle} alt='circle'/>
        <p className=' font-[600] text-[18px] sm:text-[20px] w-[75%]'>{name}</p>
        </div>
        <p className=' font-[500] text-[12px] text-[#827D77] mb-4'>Estimated returns</p>
        <p className=' text-[#17B26A] font-medium mb-5 text-[20px]'> {start} - {end}</p>
        <div>
            <img src={Graph} className=' mb-4' alt='graph'/>
        </div>
        <div className=' flex justify-between'>
            <Button
                name={"Invest now"}
            />
            <div className=' flex flex-col justify-center'>
                <p className=' text-primary-color font-medium text-[14px]'>Learn more  <KeyboardArrowRightIcon className=' text-primary-color'/> </p>
            </div>
        </div>
    </div>
  )
}

export default GraphCom