import React from 'react'
import Topsec from '../../Component/Faq/Topsec'
import Investment from '../../Component/Faq/Investment'
import Savings from '../../Component/Faq/Savings'
import AssetsF from '../../Component/Faq/Assets'

function Faq() {
  return (
    <div>
      <Topsec/>
      <Investment/>
      <Savings/>
      <AssetsF/>
    </div>
  )
}

export default Faq