import React from 'react'
import Location from '../../Assets/images/loc.svg'
function ContactCom({name, address, email, pNum,bool, change}) {
  return (
    <div onClick= {change} className={bool ? ' rounded-[12px] w-full border-2 px-3 border-[#8046F2] bg-[#FFFFFF] inline-block shadow shadow-gray-200 py-2 my-4' : 'py-2 bg-white shadow-gray-200 w-full  px-3 inline-block my-4 '}>
        <img src={Location} alt='Location'/>
        <h1 className=' text-[24px] mt-3 font-[400] text-text-color1 mb-3'>{name}</h1>
        <p className='text-[14px] font-[400] text-text-color1 mb-3'><span className=' font-[500]'>Address:</span> {address}</p>
        <p className='text-[14px] font-[400] text-text-color1 mb-3'><span className=' font-[500]'>Phone number:</span> {pNum}</p>
    </div>
  )
}

export default ContactCom