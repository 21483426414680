import React from 'react'

function Button2({onClick, name}) {
  return (
    <>
      <button 
      className=' bg-back-color2 hover:bg-white text-primary-color border-primary-color border px-5 py-2  rounded-lg '
      onClick={onClick}
      type='submit' 
     >{name}</button>   
    </>
  )
}

export default Button2