import React from 'react'
import lock from '../../Assets/images/lock.png'
import Constant from '../../Constant'
import Button from '../SelectValue/Button'
function Unlock() {
  return (
    <div className=' px-6 sm:px-24 grid pb-20 pt-20 sm:grid-cols-2 md:grid-cols-3 gap-5'>
        <div className='order-last sm:order-first'>
            <img src={lock} className=' mx-auto' alt='Unlock imag'/>
        </div>
        <div className='sm:mb-0 mb-6 md:col-span-2'>
            <div>
                <h1 className='text-[24px] font-[400] mb-5 sm:text-[44px] text-text-color1'>Unlock Possibilities: Ethical <span className={Constant.linearColor}>Asset Financing </span>with Halal Wealth</h1>
                <p className=' text-text-color1 font-normal text-[16px] sm:text-[18px] mb-5'>By committing more to Halal Wealth, you open doors to acquiring expensive items that might have seemed out of reach. Whether it's cutting-edge technology, essential equipment, or other valuable possessions, our ethical asset financing allows you to get what you need without compromise.</p>
            <Button
                name={"Get started"}
            />
            </div>
        </div>
    </div>
  )
}

export default Unlock