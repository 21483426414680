import React from 'react'

function Who() {
  return (
    <div className=' px-6 sm:px-24 py-20 grid sm:grid-cols-2 gap-5'>
        <div>
            <h1 className=' text-primary-color font-[400] text-[20px] sm:text-[40px] mb-4'>Who we are</h1>
            <p className=' text-[14px] sm:text-[16px] text-text-color1 font-medium mb-4'>With us, you have the power to invest in companies and projects that align with your values. We meticulously curate options that prioritize environmental sustainability, social responsibility, and ethical business practices. Join us in forging a future where your financial growth goes hand in hand with making a meaningful impact on the issues that matter to you.</p>
        </div>
        <div>
            <h1 className=' text-primary-color font-[400] text-[20px] sm:text-[40px] mb-4'>Our mission</h1>
            <p className=' text-[14px] sm:text-[16px] text-text-color1 font-medium mb-4'>With us, you have the power to invest in companies and projects that align with your values. We meticulously curate options that prioritize environmental sustainability, social responsibility, and ethical business practices. Join us in forging a future where your financial growth goes hand in hand with making a meaningful impact on the issues that matter to you.</p>
        </div>
    </div>
  )
}

export default Who